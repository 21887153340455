<template>
  <div>
    <Main>
      <ErrorWrapper>
        <img :src="require(`../../static/img/pages/404.svg`)" alt="404" />
        <cds-heading class="error-text" as="h3"> 404 </cds-heading>
        <p class="text-white">{{ t('common.404') }}</p>
        <router-link to="/">
          <cds-button size="default" type="primary" to="/" class="px-4"> {{ t('common.return_home') }} </cds-button>
        </router-link>
      </ErrorWrapper>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { ErrorWrapper } from './style';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: '404',
  components: {
    Main,
    ErrorWrapper,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {};
  },
});
</script>
